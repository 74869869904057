import React, { Fragment, useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, Switch, DialogContent, TextField, DialogTitle, colors, Grid, FormControlLabel } from '@mui/material';
import { useFormik, Form, FormikProvider, FieldArray } from 'formik';
import { addEditPartnerSchema } from 'src/utils/validationSchemas';
import PhoneInput from 'react-phone-input-2';
import { APIs } from 'src/apis';
import { addEditApi } from 'src/apis/admin';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { toast } from 'react-toastify';
import UploadImage from 'src/components/uploadImage';
import { IMAGE_MAX_SIZE } from 'src/contants/contants';
import './index.css';

const FORM_TEXTFIELD = [
  {
    label: 'Name',
    identifier: 'name',
  },
  {
    label: 'Email address (Login/Notifications)',
    identifier: 'emailId',
  },
  {
    label: 'Contact Name',
    identifier: 'contactPersonName',
  },
  {
    label: 'Contact Email address',
    identifier: 'contactPersonEmail',
  },
  {
    label: 'QR Code Id',
    identifier: 'bitlyQrCodeId',
  },
  {
    label: 'Email Color Hex (add with #)',
    identifier: 'emailColorCode',
  }
];

const USER_FIELD_ACCESS = [
  { label: 'Email', key: "emailId" },
  { label: 'Phone No.', key: "phoneNumber" },
  { label: 'Profile Image', key: "profileImg" },
  { label: 'DOB', key: "dob" },
  { label: 'Address', key: "address" },
  { label: 'TShirt size', key: "tshirtSize" },
  { label: 'Instagram', key: "instagram" },
  { label: 'Twitter', key: "twitter" },
]

const UPLOAD_CONSTANT = {
  PROFILE: 'Profile',
  WALLPAPER: 'Wallpaper',
};

const UPLOAD_IMAGE_FIELD = [
  {
    title: 'Partner Image',
    fieldName: 'profileImage',
    stateImage: 'image',
    identifier: UPLOAD_CONSTANT.PROFILE,
  },
  {
    title: 'Leaderboard Background',
    fieldName: 'leaderboardUiBackground',
    stateImage: 'wallpaperImage',
    identifier: UPLOAD_CONSTANT.WALLPAPER,
    warning: '(1920 x 720 pixel)',
  },
];

const USER_ACCESS = {
    profileImg : false,
    emailId : false,
    phoneNumber : false,
    tshirtSize : false,
    instagram : false,
    twitter : false,
    dob : false,
    address : false,
}

const initialValues = {
  emailId: '',
  password: '',
  contactPersonName: '',
  contactPersonEmail: '',
  name: '',
  about: '',
  notes: '',
  phoneNumber: '',
  countryCode: '',
  address: '',
  profileImage: '',
  bitlyQrCodeId: '',
  links: [''],
  leaderboardUiBackground: '',
  hashTags: '',
  twitterHandle: '',
  fieldsAccess : {
    users : USER_ACCESS
  }
};

const AddEditModal = ({ openModal, handleClose, setModalOpen, setRefresh, setSelectedRow, selectedRow }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [wallpaperUploading, setWallpaperUploading] = useState(false);
  const [image, setImage] = useState();
  const [wallpaperImage, setWallpaperImage] = useState();
  const [sizeError, setSizeError] = useState(false);
  const [removeLeaderboardImage, setRemoveLeaderboardImage] = useState(false);
  
  useEffect(() => {
    if (selectedRow) {
      if (selectedRow?.links?.length === 0) {
        selectedRow.links = [''];
      }
      if(selectedRow?.fieldsAccess && Object.keys(selectedRow?.fieldsAccess).length === 0) {
        selectedRow.fieldsAccess  = {users : USER_ACCESS}
      }
    }
  }, [selectedRow]);

  const handleFormSubmit = (data, { resetForm }) => {
    setLoading(true);

    const updatedLinks = data?.links?.filter((str) => /\S/.test(str));
    delete data?.links;
    if (updatedLinks?.length !== 0) {
      data.links = updatedLinks;
    }
    if (updatedLinks?.length === 0) {
      data.links = [];
    }

    if (data?.profileImage === '') {
      delete data?.profileImage;
    }
    if (data?.leaderboardUiBackground === '' && removeLeaderboardImage === true) {
      delete data?.leaderboardUiBackground;
      data.removeLeaderboardImage = true;
    }

    addEditApi(APIs.ADD_EDIT_PARTNER, {
      ...data,
    })
      .then(() => {
        toast.success(selectedRow ? 'Updated Successfully' : 'Added Successfully'), resetForm();
        setModalOpen(false);
        setRefresh((prev) => !prev);
        setSelectedRow('');
        setShowPassword(false);
      })
      .finally(setLoading(false));
  };

  const handleImageChange = (e, identifier) => {
    if (identifier === UPLOAD_CONSTANT.PROFILE) {
      setSizeError(false);
      if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
        setSizeError(true);
      } else {
        setImage(e?.target?.files?.[0]);
      }
    }
    if (identifier === UPLOAD_CONSTANT.WALLPAPER) {
      setSizeError(false);
      if (e?.target?.files?.[0]?.size > IMAGE_MAX_SIZE) {
        setSizeError(true);
      } else {
        setWallpaperImage(e?.target?.files?.[0]);
      }
    }
  };

  const uploadImage = (e, identifier) => {
    const data = new FormData();
    if (identifier === UPLOAD_CONSTANT.PROFILE) {
      setImageUploading(true);
      data.append('file', image);
    }
    if (identifier === UPLOAD_CONSTANT.WALLPAPER) {
      setWallpaperUploading(true);
      data.append('file', wallpaperImage);
    }

    addEditApi(APIs.UPLOAD_IMAGE, data).then((result) => {
      if (identifier === UPLOAD_CONSTANT.PROFILE) {
        setFieldValue('profileImage', result?.profileImg);
        setImage(null);
        setImageUploading(false);
      }
      if (identifier === UPLOAD_CONSTANT.WALLPAPER) {
        setFieldValue('leaderboardUiBackground', result?.profileImg);
        setWallpaperImage(null);
        setWallpaperUploading(false);
        setRemoveLeaderboardImage(true);
      }
    });
  };

  const removeImageHandler = (e, identifier) => {
    if (identifier === UPLOAD_CONSTANT.WALLPAPER) {
      setFieldValue('leaderboardUiBackground', '');
      setWallpaperImage(null);
      setRemoveLeaderboardImage(true);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: selectedRow || initialValues,
    validationSchema: addEditPartnerSchema,
    onSubmit: handleFormSubmit,
  });
  const { errors, touched, values, setFieldValue, handleSubmit, getFieldProps } = formik;

  return (
    <Dialog open={openModal} fullWidth disableEscapeKeyDown maxWidth="lg">
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>{selectedRow ? 'Edit Partner' : 'Add New Partner'}
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              {FORM_TEXTFIELD.map((obj, i) => {
                return (
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      margin="normal"
                      label={obj.label}
                      placeholder={obj.label}
                      {...getFieldProps(obj.identifier)}
                      error={Boolean(touched?.[obj?.identifier] && errors?.[obj?.identifier])}
                      helperText={touched?.[obj?.identifier] && errors?.[obj?.identifier]}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <TextField
              fullWidth
              multiline
              rows={3}
              margin="normal"
              label="About"
              {...getFieldProps('about')}
              error={Boolean(touched.about && errors.about)}
              helperText={touched.about && errors.about}
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              margin="normal"
              label="Notes (for you)"
              {...getFieldProps('notes')}
              error={Boolean(touched.about && errors.about)}
              helperText={touched.about && errors.about}
            />
            <PhoneInput
              value={`${values?.countryCode} ${values?.phoneNumber}`}
              onChange={(value, data) => {
                setFieldValue('phoneNumber', value.slice(data?.dialCode?.length));
                setFieldValue('countryCode', data?.dialCode);
              }}
              country={'us'}
              isValid={!(touched.phoneNumber && errors.phoneNumber)}
              inputStyle={{ width: '100%' }}
              containerStyle={{ marginTop: '20px', marginBottom: '10px' }}
            />

            <TextField
              fullWidth
              margin="normal"
              label="Address"
              {...getFieldProps('address')}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />

            <TextField
              fullWidth
              margin="normal"
              label="Twitter Handle (Add with @)"
              placeholder='@TwitterHandle'
              {...getFieldProps('twitterHandle')}
              error={Boolean(touched.twitterHandle && errors.twitterHandle)}
              helperText={touched.twitterHandle && errors.twitterHandle}
            />

            <TextField
              fullWidth
              margin="normal"
              label="HashTags (Add with #)"
              placeholder='#HashTags'
              {...getFieldProps('hashTags')}
              error={Boolean(touched.hashTags && errors.hashTags)}
              helperText={touched.hashTags && errors.hashTags}
            />
            Partner Links
            <Grid container marginTop={1} marginBottom={3}>

              <FieldArray
                name="links"
                render={(arrayHelpers) => (
                  <>
                    {values?.links?.map((item, index) => (
                      <Fragment key={index}>
                        <Grid item xs={4} marginTop={1}>
                          <TextField
                            fullWidth
                            name={`name.${index}`}
                            label={index === 0 ? 'Name' : ''}
                            placeholder={index === 0 ? 'Name 1' : `Name ${index + 1}`}
                            {...getFieldProps(`links.${index}.name`)}
                          />
                        </Grid>
                        <Grid item xs={5} marginTop={1} marginLeft={1}>
                          <TextField
                            fullWidth
                            name={`links.${index}`}
                            label={index === 0 ? 'Link' : ''}
                            placeholder={index === 0 ? 'Link 1' : `Link ${index + 1}`}
                            {...getFieldProps(`links.${index}.link`)}
                          />
                        </Grid>
                        {values?.links?.length !== 1 && (
                          <Grid item xs={1} md={1} marginTop={1}>
                            <IconButton
                              size="large"
                              sx={{ ml: 2, color: colors.red[500] }}
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <Iconify icon="eva:minus-square-fill" />
                            </IconButton>
                          </Grid>
                        )}

                        <Grid item xs={1} md={1} marginTop={1}>
                          <IconButton
                            size="large"
                            sx={{ ml: 2, color: 'primary.main' }}
                            onClick={() => arrayHelpers.insert(index + 1, '')}
                          >
                            <Iconify icon="eva:plus-square-fill" />
                          </IconButton>
                        </Grid>
                      </Fragment>
                    ))}
                  </>
                )}
              />
            </Grid>

            Users Fields to Show Partner
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              {USER_FIELD_ACCESS?.map((obj, i) => {
                return (
                  <Grid item xs={3} md={2} key={i}>
                   <FormControlLabel
                        control={
                          <Switch
                            checked={values?.fieldsAccess?.users?.[obj.key]}
                            onChange={(event) => setFieldValue(`fieldsAccess.users.${[obj.key]}`, event.target.checked)}
                          />
                        }
                        label={obj?.label}
                      />
                  </Grid>
                )
              })}
            </Grid>

            <Grid container spacing={2}>
              {UPLOAD_IMAGE_FIELD.map((obj, i) => {
                return (
                  <Grid item xs={12} md={6}>
                    <UploadImage
                      removeImageHandler={(e) => removeImageHandler(e, obj?.identifier)}
                      isResetButton={obj?.identifier === UPLOAD_CONSTANT.PROFILE ? false : true}
                      warning={obj?.warning}
                      isWarning={!!obj?.warning}
                      title={obj.title}
                      fieldName={obj.fieldName}
                      stateImage={obj?.identifier === UPLOAD_CONSTANT.PROFILE ? image : wallpaperImage}
                      image={
                        obj?.identifier === UPLOAD_CONSTANT.PROFILE
                          ? image
                            ? URL.createObjectURL(image)
                            : values?.profileImage
                          : wallpaperImage
                            ? URL.createObjectURL(wallpaperImage)
                            : values?.leaderboardUiBackground
                      }
                      loading={obj?.identifier === UPLOAD_CONSTANT.PROFILE ? imageUploading : wallpaperUploading}
                      fileSizeExceeded={sizeError}
                      onChange={(e) => handleImageChange(e, obj?.identifier)}
                      uploadImage={(e) => uploadImage(e, obj?.identifier)}
                      removeImage={() => {
                        if (obj?.identifier === UPLOAD_CONSTANT.PROFILE) {
                          setImage(null);
                        } else {
                          setWallpaperImage(null);
                        }
                      }}
                      touched={touched}
                      errors={errors}
                    />
                  </Grid>
                );
              })}
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowPassword(false);
                handleClose();
                formik.resetForm({
                  values: initialValues,
                });
              }}
            >
              Cancel
            </Button>

            <LoadingButton type="submit" variant="contained" loading={loading}>
              Save Partner
            </LoadingButton>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};
export default AddEditModal;
