import { useEffect, useState } from 'react';
import {
  Card,
  Dialog,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableHead,
  CircularProgress,
  Grid,
  Button,
  Checkbox,
  Avatar, 
} from '@mui/material';
import Select from 'react-select';

import Page from 'src/components/Page';
import Scrollbar from 'src/components/Scrollbar';
import { UserListToolbar } from 'src/sections/@dashboard/user';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { APIs } from 'src/apis';
import MoreMenu from 'src/components/table/MoreMenu';
import { DATE_FORMAT_TIME } from 'src/contants/contants';
import { listApi, addEditApi } from 'src/apis/admin';
import { useNavigate } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { toast } from 'react-toastify';
import { TYPE_OPTIONS, QUESTION_TYPE, TYPE_TWO } from './addEdit';
import { confirmAlert } from 'react-confirm-alert';
import { map, set } from 'lodash';
import Label from 'src/components/Label';
import JSONViewerModal from './JSONViewerModal';
import ImageModal from './imageModal';
import { openLinkNewTab } from 'src/utils/helper';
import { useSelector } from 'react-redux';
import BulkUpdateQuesionsModal from './bulkUpdateQuestions';

const TABLE_HEAD = [
  { id: '' },
  { id: '', label: 'Select Question', alignRight: false },
  { id: 'isVerified', label: 'Verified?', alignRight: false },
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'questionHImage', label: 'Question H Image', alignRight: false },
  { id: 'question', label: 'Question', alignRight: false },
  { id: 'options', label: 'Options', alignRight: false },
  { id: 'correct', label: 'Correct Option', alignRight: false },
  { id: 'type', label: 'Type', alignRight: false },
  { id: 'answerCalculated', label: 'Answer Calculated', alignRight: false },
  { id: 'releasePeriod', label: 'Release Period', alignRight: false },
  { id: 'category', label: 'Category', alignRight: false },
  { id: 'topic', label: 'Topic', alignRight: false },
  { id: 'subtopic', label: 'Sub Topic', alignRight: false },
  { id: 'chatType', label: 'Chat Type', alignRight: false },
  { id: 'chatMessage', label: 'Chat Message', alignRight: false },
  // { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'difficultyLevel', label: 'Difficulty Level', alignRight: false },
  { id: 'points', label: 'Points', alignRight: false },
  
  { id: 'questionHeader', label: 'Question Header', alignRight: false },
  { id: 'questionType', label: 'Question Type', alignRight: false },
  { id: 'timer', label: 'Timer', alignRight: false },
  { id: 'timerPoints', label: 'Timer Points', alignRight: false },
  { id: 'type2', label: 'Type 2', alignRight: false },
  { id: 'explanation', label: 'Explanation', alignRight: false },
  { id: 'createdAt', label: 'Created Date', alignRight: false },
  { id: 'updatedAt', label: 'Updated Date', alignRight: false },
];

const loadTypeData = [
  // {label : "All", value : null},
  { label: 'Verified', value: true },
  { label: 'Un-Verified', value: false },
];

const removeNullValues = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    }
  }
  return obj;
};

const QuestionBankList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [questionList, setQuestionList] = useState([]);
  const [search, setSearch] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [verifiedFilter, setVerifiedFilter] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [jsonArray, setJsonArray] = useState([]);
  const [success, setSuccess] = useState(false);
  const [openJSONViewer, setOpenJSONViewer] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(false);
  const [openBulkUpdate, setopenBulkUpdateFlow] = useState(false);

  const user = useSelector((state) => state?.auth);

  useEffect(() => {
    listing();
  }, [rowsPerPage, searchFilter, page, success, verifiedFilter]);

  const listing = () => {
    setLoading(true);
    const data = {
      skip: page * rowsPerPage,
      limit: rowsPerPage,
      search: searchFilter,
    };

    if (verifiedFilter !== null) data.isVerified = verifiedFilter;

    listApi(APIs.QUESTION_BANK_LIST, { ...data })
      .then((res) => {
        setQuestionList(res?.data);
        setTotalCount(res?.count);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
    debounce(() => {
      setSearchFilter(event.target.value);
    }, 600)();
  };

  const getTypevalue = (array, value) => {
    const obj = array.find((obj, i) => obj.value === value);
    return obj?.label;
  };

  const CheckedHandler = (id) => {
    const obj = jsonArray?.find((obj, i) => obj.id === id);
    let returnObj = false;
    if (obj) {
      returnObj = true;
    }
    return returnObj;
  };

  const verifyQuestionHandle = (item) => {
    let isVerified = true;

    if (item && item[0].isVerified) {
      isVerified = false;
    }

    confirmAlert({
      title: 'Confirm to Approved?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            addEditApi(APIs.VERIFY_QUESTION, { questions: map(item || jsonArray, 'id'), isVerified }).then((result) => {
              toast.success('Verified Successfully');
              setSuccess((prev) => !prev);
            });
          },
        },
        {
          label: 'No',
        },
      ],
    });
  };

  //function to open a full size image
  const showFullImage = (src) => {
    setOpenImageModal(true);
    setImageSrc(src);
  };

  const closeBulkUpdateFlow = () => {
    setopenBulkUpdateFlow(!openBulkUpdate);
    setSuccess((prev) => !prev);
    setJsonArray([]);
  };

  const optionColumn = (nameOptions, options) => {
    return (
      <ul>
        {nameOptions?.length !== null && nameOptions?.length > 0
          ? nameOptions.map((opt, i) => {
              return (
                <Typography
                  key={i}
                  sx={{
                    width: '200px',
                    display: 'flex',
                    margin: '5px 0px',
                    alignItems: 'center',
                  }}
                >
                  <li style={{ width: '150px' }}>{opt}</li>
                  <Avatar
                    alt={options[i]}
                    src={options[i]}
                    onClick={() => showFullImage(options[i])}
                    style={{ cursor: 'pointer' }}
                  />
                </Typography>
              );
            })
          : options.map((opt, i) => {
              return (
                <Typography  key={i} sx={{ width: '100px' }}>
                  <li>{opt}</li>
                </Typography>
              );
            })}
      </ul>
    );
  };

  return (
    <Page title="Contests">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Question Bank
          </Typography>
        </Stack>

        <Card>
          <Grid container spacing={2} padding={1}>
            <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <UserListToolbar numSelected={selected.length} filterName={search} onFilterName={handleSearch} />
              <Grid>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenJSONViewer(true)}
                >
                  Questions
                </Button>
              </Grid>

              <Select
                isClearable
                fullWidth
                placeholder={'Filter Verified?'}
                options={loadTypeData}
                name={'filter'}
                onChange={(data) => setVerifiedFilter(data?.value)}
              />

              <Button
                color="primary"
                variant="contained"
                startIcon={<ContentCopyIcon />}
                onClick={() => {
                  navigator.clipboard.writeText(JSON.stringify(jsonArray));
                  toast.success('Copied Successfully');
                }}
                disabled={jsonArray?.length === 0 ? true : false}
              >
                Copy Json
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<SystemUpdateAltIcon />}
                onClick={() => {
                  setopenBulkUpdateFlow(true);
                }}
                disabled={jsonArray?.length === 0 ? true : false}
              >
                Bulk Update
              </Button>
              <Button
                color="primary"
                variant="contained"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={() => {
                  verifyQuestionHandle();
                }}
                disabled={jsonArray?.length === 0 ? true : false}
              >
                Verify Questions?
              </Button>
            </Grid>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              {loading ? (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <Table>
                  <TableHead>
                    <TableRow>
                      {TABLE_HEAD.map((headCell, index) => (
                        <TableCell key={index} align={headCell.alignRight ? 'right' : 'left'}>
                          {headCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {questionList.map((row, index) => {
                      const isItemSelected = selected.indexOf(row.id) !== -1;

                      const hasEditPermission = user?.roles?.some(
                        (role) => role?.title === 'Question Bank' && role?.edit === true
                      );
                      const hasDeletePermission = user?.roles?.some(
                        (role) => role?.title === 'Question Bank' && role?.delete === true
                      );

                      const menuOptions = [];

                      if (user?.isSuperAdmin || hasEditPermission) {
                        menuOptions.push(
                          {
                            label: 'Edit',
                            icon: 'eva:edit-fill',
                            onClick: () => openLinkNewTab(`/edit-question/${row.id}`),
                          },
                          {
                            label: 'Clone Question',
                            icon: 'eva:copy-outline',
                            onClick: () => {
                              confirmAlert({
                                title: <h6>Confirm to clone</h6>,
                                message: 'Are you sure to do this?',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      delete row.id;
                                      delete row.createdAt;
                                      delete row.updatedAt;
                                      if (row.timer === null) row.timer = '0';
                                      if (row.timerPoints === null) row.timerPoints = '0';
                                      if (row.chatType === null) row.chatType = 'NA';
                                      row.fromJson = true;
                                      row.cloneQuestion = true;
                                      addEditApi(APIs.ADD_GENERATED_QUESTION, { question: [row] }).then((result) => {
                                        navigate(`/edit-question/${result?.data?.id}`);
                                      });
                                    },
                                  },
                                  {
                                    label: 'No',
                                  },
                                ],
                              });
                            },
                          },
                          {
                            label: 'Verify/UnVerify Question?',
                            icon: 'eva:checkmark-circle-2-outline',
                            onClick: () => {
                              verifyQuestionHandle([row]);
                            },
                          },
                          // {
                          //   label: 'Delete',
                          //   icon: 'eva:trash-2-outline',
                          //   onClick: () => {
                          //     confirmAlert({
                          //       title: 'Confirm for Delete',
                          //       message: 'Are you sure to do this.',
                          //       buttons: [
                          //         {
                          //           label: 'Yes',
                          //           onClick: () => {
                          //             addEditApi(APIs.DELETE_REQUEST, { type: 8, id: row.id }).then((result) => {
                          //               toast.success('Deleted Successfully');
                          //               setSuccess((prev) => !prev);
                          //             });
                          //           },
                          //         },
                          //         {
                          //           label: 'No',
                          //         },
                          //       ],
                          //     });
                          //   },
                          // }
                        );
                      } 
                
                      if (user?.isSuperAdmin || hasDeletePermission) {
                          menuOptions.push({
                            label: 'Delete',
                            icon: 'eva:trash-2-outline',
                            onClick: () => {
                              confirmAlert({
                                title: 'Confirm for Delete',
                                message: 'Are you sure to do this.',
                                buttons: [
                                  {
                                    label: 'Yes',
                                    onClick: () => {
                                      addEditApi(APIs.DELETE_REQUEST, { type: 8, id: row.id }).then((result) => {
                                        toast.success('Deleted Successfully');
                                        setSuccess((prev) => !prev);
                                      });
                                    },
                                  },
                                  {
                                    label: 'No',
                                  },
                                ],
                              });
                            },
                          });
                      }
                      
                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {(user?.isSuperAdmin || hasEditPermission || hasDeletePermission) && (
                            <TableCell align="right">
                              <MoreMenu options={menuOptions} />
                            </TableCell>
                          )}

                          <TableCell>
                            <Typography sx={{ width: '70px' }}>
                              <Checkbox
                                onChange={(e) => {
                                  if (e?.target?.checked === true) {
                                    const setObj = removeNullValues(row);
                                    setJsonArray([...jsonArray, setObj]);
                                  } else {
                                    setJsonArray(jsonArray?.filter((obj, i) => obj?.id !== row.id));
                                  }
                                }}
                                checked={CheckedHandler(row.id)}
                              />
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '70px' }}>
                              <Label variant="ghost" color={row.isVerified ? 'success' : 'error'}>
                                {row.isVerified ? 'Yes' : 'No'}
                              </Label>
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '170px', wordWrap: 'break-word' }}>{row.id}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            {row.questionHImage !== null ? (
                              <Avatar
                                alt={row.questionHImage}
                                src={row.questionHImage}
                                onClick={() => showFullImage(row?.questionHImage)}
                                style={{ cursor: 'pointer' }}
                              />
                            ) : (
                              '-'
                            )}
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '250px' }}>{row.question}</Typography>
                          </TableCell>
                          <TableCell align="left">{optionColumn(row.nameOptions, row.options)}</TableCell>
                          <TableCell align="left">{row.correct?.join(',')}</TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '130px' }}>{getTypevalue(TYPE_OPTIONS, row.type)}</Typography>
                          </TableCell>
                          <TableCell align="left">{row.answerCalculated}</TableCell>
                          <TableCell align="left">{row.releasePeriod}</TableCell>
                          <TableCell align="left">{row.category}</TableCell>
                          <TableCell align="left">{row.topic}</TableCell>
                          <TableCell align="left">{row.subtopic}</TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '70px' }}>{row.chatType !== null ? row.chatType : '-'}</Typography>
                          </TableCell>
                          <TableCell align="left">{row.chatMessage ? row.chatMessage : '-'}</TableCell>
                          <TableCell align="left">{row.difficultyLevel}</TableCell>
                          <TableCell align="left">{row.points}</TableCell>
                          
                          <TableCell align="left">{row.questionHeader !== null ? row.questionHeader : '-'}</TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '100px' }}>
                              {getTypevalue(QUESTION_TYPE, row.questionType)}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '100px' }}>{row.timer}</Typography>
                          </TableCell>
                          <TableCell align="left">{row.timerPoints}</TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '100px' }}>
                              {row.type2 !== null ? getTypevalue(TYPE_TWO, row.type) : '-'}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '250px' }}>{row.explanation}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '100px' }}>
                              {moment(row.createdAt).format(DATE_FORMAT_TIME)}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Typography sx={{ width: '100px' }}>
                              {moment(row.updatedAt).format(DATE_FORMAT_TIME)}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 15, 25]}
            component="div"
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage=""
            showFirstButton
            showLastButton
          />
        </Card>
      </Container>
      <JSONViewerModal
        isOpen={openJSONViewer}
        closeModal={(refresh) => {
          setOpenJSONViewer(false);
          if (refresh) {
            setSuccess((prev) => !prev);
          }
        }}
      />
       <Dialog key="bulkUpdateFlow" open={openBulkUpdate} fullWidth>
        <BulkUpdateQuesionsModal
          closeModal={closeBulkUpdateFlow}
          selectedQuestions={jsonArray}
        />
      </Dialog>
      <ImageModal openModal={openImageModal} closeModal={(e) => setOpenImageModal(false)} img={imageSrc} />
    </Page>
  );
};

export default QuestionBankList;
